import * as React from "react"
import { Helmet } from "react-helmet"

// Components
import Header from '../components/Header.jsx'
import Footer from '../components/Footer.jsx'
// images
import citybackground from '../images/hero-background.jpg';

// markup
const UnderConstruction = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Under-Construction | INVENSE - Internet Of Things Telematic Solutions</title>
        <meta name="description" content="Invense is a IOT solutions platform. Where business can explore internet of things based solutions to thier business problems. We provide state of the art equipment and solutions to a wide range of industries." />
        <meta name="keyboards" content="IOT, IOT solutions, IOT consultants, engineering" />
      </Helmet>
      <main>
        <Header />
        <section
          id="hero"
          className="h-screen"
          style={{ backgroundImage: `url(${citybackground})`, backgroundSize: 'cover' }}
        >
          <div className="max-w-6xl mx-auto pt-20 px-2 sm:px-6 lg:px-8 h-full flex items-center md:justify-center">
            <div className="relative flex-1">
              <h1 className="border-l-2 border-yellow-500 pl-2 text-2xl text-white">Under Construction
                <span className="block text-4xl text-yellow-500 font-bold"> This page will be available soon! </span>
              </h1>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  )
}

export default UnderConstruction;
